import { Component, OnInit, signal, Inject } from '@angular/core';
import { ThemeService } from './core/services/theme.service';
import { Router, RouterOutlet } from '@angular/router';
import { NgClass } from '@angular/common';
import { ResponsiveHelperComponent } from './shared/components/responsive-helper/responsive-helper.component';
import { NgxSonnerToaster } from 'ngx-sonner';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { AppState } from './store/app.store';
import { changeLanguage } from './store/actions/language.actions';
import { AuthService } from '@auth0/auth0-angular';
import { login, logout, setUser } from './store/actions/auth.actions';
import { TranslationService } from './core/services/translation.service';
import { selectCurrentLanguage } from './store/selectors/language.selectors';
import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID } from '@angular/core';
import { take, switchMap, map, catchError } from 'rxjs/operators';
import { combineLatest, EMPTY } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [NgClass, RouterOutlet, ResponsiveHelperComponent, NgxSonnerToaster],
})
export class AppComponent implements OnInit {
  title = 'Limitless Parking';
  dir = signal('ltr');
  lang = signal('en');
  constructor(private auth: AuthService,
    private translationService: TranslationService,
    public themeService: ThemeService,
    private translate: TranslateService,
    private store: Store<AppState>
  ) {

    const language = localStorage.getItem('lng');
    const dir = localStorage.getItem('dir');
    if (language && dir) {
      this.dir.set(dir);
      this.lang.set(language);
      this.translate.setDefaultLang(language);
      // Set current language
      this.translate.use(language);
      this.translationService.changeLang(language);
    } else {
      this.dir.set('ltr');
      this.lang.set('en');
      this.translate.setDefaultLang('en');
      // Set current language
      this.translate.use('en');
      this.translationService.changeLang('en');


    }

    this.store.select(selectCurrentLanguage).subscribe(language => {
      if (language) {
        const dir = language === 'ar' ? 'rtl' : 'ltr';
        this.dir.set(dir);
        this.lang.set(language);
      }
    });
  }
  ngOnInit(): void {
    // Check if we're already handling auth
    const isHandlingAuth = sessionStorage.getItem('handling_auth');
    if (isHandlingAuth) {
      return;
    }

    // Set handling auth flag in sessionStorage instead of localStorage
    sessionStorage.setItem('handling_auth', 'true');

    const authData = localStorage.getItem('auth');
    if (authData) {
      try {
        const { user, token } = JSON.parse(authData);
        if (user && token) {
          this.store.dispatch(login({ user, token }));
          // Clear handling auth flag after successful login
          sessionStorage.removeItem('handling_auth');
        } else {
          this.handleAuth0Login();
        }
      } catch (error) {
        this.handleAuth0Login();
      }
    } else {
      this.handleAuth0Login();
    }
  }

  private handleAuth0Login(): void {
    this.auth.isAuthenticated$.pipe(
      take(1),
      switchMap(isAuthenticated => {
        if (isAuthenticated) {
          return combineLatest([
            this.auth.user$.pipe(take(1)),
            this.auth.getAccessTokenSilently({
              authorizationParams: {
                organization: localStorage.getItem('org_id')
              }
            }).pipe(take(1))
          ]).pipe(
            map(([user, token]) => {
              if (user && token && user['org_id']) {
                this.store.dispatch(login({ user, token }));
              } else {
                this.clearAuthAndRedirect();
              }
              // Clear handling auth flag
              sessionStorage.removeItem('handling_auth');
            }),
            catchError(() => {
              this.clearAuthAndRedirect();
              return EMPTY;
            })
          );
        } else {
          this.clearAuthAndRedirect();
          return EMPTY;
        }
      })
    ).subscribe({
      error: () => {
        this.clearAuthAndRedirect();
      }
    });
  }

  private clearAuthAndRedirect(): void {
    // Clear auth data
    localStorage.removeItem('auth');
    sessionStorage.removeItem('handling_auth');
    this.store.dispatch(logout());
    
    // Redirect to login with specific options for mobile
    this.auth.loginWithRedirect({
      appState: { 
        returnTo: window.location.pathname 
      },
      authorizationParams: {
        prompt: 'login',
        // Add these parameters to help with mobile browsers
        display: 'popup',
        response_type: 'token id_token',
      }
    });
  }
}
