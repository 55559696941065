// auth.effects.ts
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AuthService } from '@auth0/auth0-angular';
import { login, logout, setError, setUser, setStatistics } from '../actions/auth.actions';
import { of, EMPTY } from 'rxjs';
import { map, catchError, tap, switchMap, take } from 'rxjs/operators';
import { GlobalService } from 'src/app/core/services/APIs/dashboard/global.service';
import { RoleService } from 'src/app/core/services/APIs/user/role.service';
import { loadRoles } from '../actions/role.actions';
import { Store } from '@ngrx/store';
import { transformPayload } from 'src/app/core/utils/array-utils';
import { loadSites } from '../actions/site.actions';
import { loadConstants } from '../actions/constants.actions';
import { environment } from 'src/environments/environment';
@Injectable()
export class AuthEffects {
  constructor(private actions$: Actions,
    private store: Store,
    private auth: AuthService,
    private globalService: GlobalService,) { }

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(login),
      take(1),
      switchMap(({ user, token }) => {
        // If we already have user and token, just store them
        if (user && token) {
          return of(setUser({ user }));
        }

        // Check if auth is already in progress
        if (sessionStorage.getItem('auth_in_progress')) {
          return EMPTY;
        }

        // Set auth in progress flag
        sessionStorage.setItem('auth_in_progress', 'true');

        // Otherwise initiate login flow with mobile-friendly options
        return this.auth.loginWithRedirect({
          appState: { 
            returnTo: window.location.pathname 
          },
          authorizationParams: {
            organization: localStorage.getItem('org_id') || '',
            prompt: 'select_account',
            // Add mobile-friendly parameters
            display: 'popup',
            response_type: 'token id_token',
          }
        }).pipe(
          tap(() => {
            try {
              this.auth.handleRedirectCallback();
            } catch (error) {
              console.error('Redirect callback error:', error);
            }
          }),
          switchMap(() =>
            this.auth.user$.pipe(
              take(1),
              switchMap((user) => 
                this.auth.getAccessTokenSilently({
                  authorizationParams: {
                    organization: user?.['org_id']
                  }
                }).pipe(
                  take(1),
                  map(token => ({
                    user,
                    token
                  }))
                )
              )
            )
          ),
          map(({ user, token }) => {
            sessionStorage.removeItem('auth_in_progress');
            return login({ user, token });
          }),
          catchError((error) => {
            sessionStorage.removeItem('auth_in_progress');
            return of(setError({ error: error.message }));
          })
        );
      })
    )
  );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(logout),
        take(1),
        tap(() => {
          this.clearAuthData();
          this.auth.logout({
            logoutParams: { 
              returnTo: window.location.origin,
              clientId: environment.auth.clientId
            }
          });
        })
      ),
    { dispatch: false }
  );

  persistAuth$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(login),
        tap(({ user, token }) => {
          if (user?.org_id) {
            // Store auth data
            this.storeAuthData(user, token);
            
            // Dispatch necessary actions
            this.store.dispatch(setUser({ user }));
            this.store.dispatch(loadRoles());
            this.store.dispatch(loadSites());
            this.store.dispatch(loadConstants());

            // Handle statistics
            this.handleStatistics();
          } else {
            // Clear auth progress flag before redirecting
            sessionStorage.removeItem('auth_in_progress');
            
            // If no org_id, trigger organization selection
            this.auth.loginWithRedirect({
              appState: { returnTo: window.location.pathname },
              authorizationParams: {
                prompt: 'select_account',
                display: 'popup',
                response_type: 'token id_token',
              }
            });
          }
        })
      ),
    { dispatch: false }
  );

  checkAuth$ = createEffect(
    () =>
      this.auth.isAuthenticated$.pipe(
        take(1),
        switchMap((isAuthenticated) => {
          if (!isAuthenticated) {
            return this.auth.user$.pipe(
              take(1),
              switchMap(user => {
                if (user) {
                  return this.auth.getAccessTokenSilently().pipe(
                    take(1),
                    tap(newToken => {
                      if (newToken) {
                        const authData = JSON.parse(localStorage.getItem('auth') || '{}');
                        this.storeAuthData(user, newToken);
                        this.store.dispatch(setUser({ user }));
                      } else {
                        this.handleLogout();
                      }
                    }),
                    catchError(() => {
                      this.handleLogout();
                      return of(null);
                    })
                  );
                }
                return EMPTY;
              })
            );
          }
          return EMPTY;
        })
      ),
    { dispatch: false }
  );

  private handleStatistics(): void {
    const storedStats = localStorage.getItem('statistics');
    if (!storedStats) {
      this.globalService.getStatistics().subscribe((res: any) => {
        const transformedStats = transformPayload(res?.data);
        localStorage.setItem('statistics', JSON.stringify(transformedStats));
        this.store.dispatch(setStatistics({ statistics: transformedStats }));
      });
    } else {
      const statistics = JSON.parse(storedStats);
      this.store.dispatch(setStatistics({ statistics }));
    }
  }

  private storeAuthData(user: any, token: string): void {
    localStorage.setItem('org_id', user.org_id);
    localStorage.setItem('auth', JSON.stringify({ user, token }));
  }

  private clearAuthData(): void {
    localStorage.clear();
    sessionStorage.removeItem('auth_in_progress');
    localStorage.removeItem('auth');
  }

  private handleLogout(): void {
    this.clearAuthData();
    this.auth.logout({
      logoutParams: { 
        returnTo: window.location.origin,
        clientId: environment.auth.clientId
      }
    });
  }
}


